* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  flex: 1;
}

@font-face {
  font-family: 'MontserratExtraBold';
  src: url('../fonts/Montserrat-ExtraBold.ttf') format('truetype'),
      url('../fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratBold';
  src: url('../fonts/Montserrat-Bold.ttf') format('truetype'),
      url('../fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontserratRegular';
  src: url('../fonts/Montserrat-Regular.ttf') format('truetype'),
      url('../fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}