.imgLogoContainer{
    position: relative;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    img{
        width: 80%;
    }
}