.title{
    font-size: 1.65em;
    text-align: center;
}
.cursosContainer {
    display: flex;
    height: 450px;
    margin-top: 15px;
    flex-direction: row;

    .imgContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 50%;
        box-sizing: border-box;
        padding: 10px 15px; 
        img{
            width: 90%;
            box-shadow: 5px 5px 15px rgba(255, 0, 0, 0.1);
        }
    }

    .infoCursoContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        width: 50%;
        box-sizing: border-box;
        padding: 10px 40px; 
        .titleService{
            font-size: 1.5em;
        }
        .textService{
            margin-top: 30px;
            font-size: 1em;
        }
        .btnServiceContainer{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60% !important;
            margin-top: 40px;
            .btnService{
                width: 100%;
                height: 45px;
                background-color: black;
                box-shadow: 5px 5px 10px rgba(255, 0, 0, 0.2);
                color: white;
                letter-spacing: 1px;
                transition: .3s ease-in-out;
                &:hover{
                    background-color: rgb(92, 92, 92);
                    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.258);
                    transition: .3s ease-in-out;
                }
            }

        }
    }
}

#servicio2, #servicio4, #servicio6 {
    flex-direction: row-reverse !important;
    background-color: rgb(248,249,250)
}

@media screen and (max-width: 768px) {
    .title{
        font-size: 1.65em;
        text-align: center;
        margin-top: 25px;
    }
    .cursosContainer {
        display: flex;
        height: auto;
        flex-direction: column;
    
        .imgContainer{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            box-sizing: border-box;
            padding: 10px 15px; 
            margin-top: 40px;
            img{
                width: 90%;
                box-shadow: 5px 5px 15px rgba(255, 0, 0, 0.1);
            }
        }
    
        .infoCursoContainer {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            box-sizing: border-box;
            padding: 10px 25px; 
            margin-top: 10px;
            .titleService{
                font-size: 1.5em;
                text-align: center;
            }
            .textService{
                margin-top: 15px;
                font-size: 1em;
                text-align: center;
            }
            .btnServiceContainer{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80% !important;
                margin-top: 40px;
                margin-bottom: 25px;
                .btnService{
                    width: 100%;
                    height: 45px;
                    background-color: black;
                    box-shadow: 5px 5px 10px rgba(255, 0, 0, 0.1);
                    color: white;
                    letter-spacing: 1px;
                    transition: .3s ease-in-out;
                    &:hover{
                        background-color: rgb(92, 92, 92);
                        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.258);
                        transition: .3s ease-in-out;
                    }
                }
    
            }
        }
    }

    #servicio1 {
        border-top:1px solid rgba(255, 0, 0, 0.05);
    }
    
    #servicio2, #servicio4, #servicio6 {
        flex-direction: column !important;
        background-color: rgb(248,249,250);
        border:1px solid rgba(255, 0, 0, 0.05);
    }
}