.imgFondoContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 2000;
    img{
        width: 100%;
    }
}