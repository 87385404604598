.carouselContainer{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    gap: 30px;
    .carouselText{
        position: relative;
        width: 50%;

        .title1{
            font-size: 2em;
        }
        .title2{
            font-size: 1.25em;
        }
        .firstText{
            font-size: 1em;
        }
        .containerDatosMain{
            position: relative;
            width: 100%;
            display: flex;
            justify-content: start;
            align-items: center;
            .containerDatos{
                position: relative;
                width: 22.5%;
                display: flex;
                justify-content: start;
                align-items: start;
                flex-direction: column;
                .contadorNum{
                    font-size: 2em;
                    color: red;
                }
                .contadorNumText{
                    font-size: 1.4em;
                    margin-top: -25px;
                }
            }
        }
        .containerBtn1{
            .btnCarousel{
                background-color: rgb(33,37,41);
                color: rgb(252, 0, 0);
                width: 65%;
                border: 1px solid rgba(255, 0, 0, 0.10);
                box-shadow: 3px 3px 5px rgba(255, 0, 0, 0.15);
                letter-spacing: 1px;
                &:hover{
                    background-color: rgb(62, 65, 68);
                    color: white;
                    box-shadow: 3px 3px 5px rgba(126, 126, 126, 0);
                }
            }
        }
    }
    .carouselImages{
        position: relative;
        width: 50%;
    }
}

@media screen and (max-width: 1200px) {
    .carouselContainer{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 15px;
        .carouselText{
            text-align: center;
            position: relative;
            width: 50%;
            .title1{
                font-size: 2em;
            }
            .title2{
                font-size: 1.25em;
                margin-top: 10px;
            }
            .firstText{
                font-size: 1em;
            }
            .containerDatosMain{
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .containerDatos{
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: center !important;
                    align-items: center !important;
                    flex-direction: column;
                    .contadorNum{
                        font-size: 3em;
                        color: red;
                    }
                    .contadorNumText{
                        font-size: 1.4em;
                        margin-top: -25px;
                    }
                }
            }
            .containerBtn1{
                .btnCarousel{
                    background-color: rgb(33,37,41);
                    color: rgb(255, 255, 255);
                    font-size: 1.25em;
                    width: 100%;
                    border: 1px solid rgba(255, 0, 0, 0.10);
                    box-shadow: 3px 3px 5px rgba(255, 0, 0, 0.15);
                    letter-spacing: 1px;
                    &:hover{
                        background-color: rgb(62, 65, 68);
                        color: white;
                        box-shadow: 3px 3px 5px rgba(126, 126, 126, 0);
                    }
                }
            }
        }
        .carouselImages{
            position: relative;
            width: 50%;
        }
    }
}

@media screen and (max-width: 800px) {
    .carouselContainer{
       flex-direction: column;
    }
}

@media screen and (max-width: 480px) {
    .carouselContainer{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0px 15px;
        margin-top: 20px;
        .carouselText{
            text-align: center;
            text-align: center;
            position: relative;
            width: 100%;
            .title1{
                font-size: 2em;
            }
            .title2{
                font-size: 1.25em;
                margin-top: 10px;
            }
            .firstText{
                font-size: 1em;
            }
            .containerDatosMain{
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .containerDatos{
                    position: relative;
                    width: 100%;
                    display: flex;
                    justify-content: center !important;
                    align-items: center !important;
                    flex-direction: column;
                    .contadorNum{
                        font-size: 3em;
                        color: red;
                    }
                    .contadorNumText{
                        font-size: 1.4em;
                        margin-top: -25px;
                    }
                }
            }
            .containerBtn1{
                .btnCarousel{
                    background-color: rgb(33,37,41);
                    color: rgb(255, 255, 255);
                    font-size: 1.25em;
                    width: 100%;
                    border: 1px solid rgba(255, 0, 0, 0.10);
                    box-shadow: 3px 3px 5px rgba(255, 0, 0, 0.15);
                    letter-spacing: 1px;
                    &:hover{
                        background-color: rgb(62, 65, 68);
                        color: white;
                        box-shadow: 3px 3px 5px rgba(126, 126, 126, 0);
                    }
                }
            }
        }
        .carouselImages{
            position: relative;
            width: 100%;
        }
    }
}