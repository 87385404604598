.containerCards {
    margin-top: 100px !important;
    position: relative;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 70px;
    z-index: 0;

    .containerCard {
        position: relative;
        height: 240px !important;
        width: 350px !important;
        border: 1px solid #ffffff;
        padding: 5px 10px !important;
        background-color: white !important;
        box-shadow: 7px 7px 10px rgba(7, 29, 61, 0.1),
          -2px -2px 10px rgb(7, 29, 61, 0.1);
        transition: 0.3s ease-in-out;
        border: 1px solid rgba(255, 0, 0, 0.15);
       
        .imgCard{
            width: 75px;
            margin-top: -50px;
        }

        .containerCardText{
            margin-top: 25px;
            .firstText{
                font-size: 1.3em;
                color: red;
            }
            .textIdentificador{
                font-size: .85em;
            }
        }
      }
  }

  @media screen and (max-width: 480px){
    .containerCards {
        margin-top: 100px !important;
        position: relative;
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 70px;
        z-index: 0;
    
        .containerCard {
            position: relative;
            height: 230px !important;
            width: 350px !important;
            border: 1px solid #ffffff;
            padding: 5px 10px !important;
            background-color: white !important;
            box-shadow: 7px 7px 10px rgba(7, 29, 61, 0.1),
              -2px -2px 10px rgb(7, 29, 61, 0.1);
            transition: 0.3s ease-in-out;
            border: 1px solid rgba(255, 0, 0, 0.15);
           
            .imgCard{
                width: 75px;
                margin-top: -50px;
            }
    
            .containerCardText{
                margin-top: 25px;
                .firstText{
                    font-size: 1.35em;
                    color: red;
                }
                .textIdentificador{
                    font-size: .9em;
                }
            }
          }
      }
  }
  